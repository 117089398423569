import {getHebJewishMonths, getJewishDate} from "jewish-dates-core";
import {formatJewishDateInHebrew, toHebrewJewishDate} from "jewish-date";


export function convertToHebrewLetters(year) {
    const date = new Date(year - 3760, 0, 1);
    const hebrewDate = toHebrewJewishDate(getJewishDate(date));
    return hebrewDate.year;
}

export function convertToGregorianYear(hebrewYear) {
    const hebrewToNumberMap = {
        'א': 1, 'ב': 2, 'ג': 3, 'ד': 4, 'ה': 5,
        'ו': 6, 'ז': 7, 'ח': 8, 'ט': 9, 'י': 10,
        'כ': 20, 'ל': 30, 'מ': 40, 'נ': 50,
        'ס': 60, 'ע': 70, 'פ': 80, 'צ': 90,
        'ק': 100, 'ר': 200, 'ש': 300, 'ת': 400,
        'ך': 20, 'ם': 40, 'ן': 50, 'ף': 80, 'ץ': 90,
        'תק': 500, 'תר': 600, 'תש': 700, 'תת': 800, 'תתק': 900
    };

    // Initialize the numeric value of the Hebrew year
    let numericValue = 0;

    // Check if the first character is 'ה'
    if (hebrewYear[0] === 'ה') {
        // Remove the first character (drop 'ה') for the computation
        hebrewYear = hebrewYear.substring(1);
    }

    // Iterate over each character in the Hebrew year string
    for (let char of hebrewYear) {
        if (hebrewToNumberMap[char] !== undefined) {
            numericValue += hebrewToNumberMap[char];
        } else {
            console.warn(`Unrecognized character '${char}' in Hebrew year string.`);
        }
    }

    // Hebrew years commonly omit the millennium (thousands digit), so we add 5000
    return numericValue + 5000;
}

export const getHebrewMonth = () => {
    const month = getHebJewishMonths().map(month => month.text)
    const adarIdx = month.findIndex(month => month === 'שבט')
    const res = [...month.slice(0, adarIdx+1), 'אדר', ...month.slice(adarIdx + 1)].flat()
    console.log(res)
    return res
}

export const hebrewComparator = (valueA, valueB) => {
    return valueA?.localeCompare(valueB, 'he');
};

export const georgianToStringHebrewDate = (date) => {
    const d = new Date(date);
    const timeFormatted = d.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    return formatJewishDateInHebrew(getJewishDate(d)) + `\t ${timeFormatted}`
};
