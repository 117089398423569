import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { AgGridReact } from "ag-grid-react";
import { MenuOptions } from "./menuOptions";
import { hebrewComparator } from '../../utils/utils';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useTravelApprovedStore } from "../../store/traveApprovedStore";
import "./style.css"

const Table = observer(({ data }) => {
    const gridRef = useRef();
    const containerRef = useRef();
    const { travelApprovedStore, fetchData } = useTravelApprovedStore();
    const [rowData, setRowData] = useState(data || []);

    const memoizedFetchData = useCallback(fetchData, []);

    useEffect(() => {
        memoizedFetchData();
    }, [memoizedFetchData]);

    useEffect(() => {
        if (travelApprovedStore.data) {
            setRowData(travelApprovedStore.data);
            if (gridRef.current) {
                autoSizeAllColumns(gridRef.current);
                setColumnsWidth(gridRef.current);
            }
        }
    }, [travelApprovedStore.data]);

    const onGridReady = (params) => {
        gridRef.current = params.api;
        autoSizeAllColumns(params.api);
        setColumnsWidth(params.api);
    };

    const handleResize = () => {
        if (gridRef.current) {
            setColumnsWidth(gridRef.current);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const autoSizeAllColumns = (api) => {
        const allColumnIds = api.getColumnDefs().map(colDef => colDef.field);
        api.autoSizeColumns(allColumnIds, false);
    };

    const setColumnsWidth = (api) => {
        const allColumns = api.getColumnDefs();
        const columnState = api.getColumnState();
        const sumWidth = columnState.reduce((sum, col) => sum + col.width, 0);
        const containerWidth = containerRef.current.getBoundingClientRect().width;

        if (sumWidth < containerWidth) {
            const mapPercent = {};
            allColumns.forEach(col => {
                mapPercent[col.field] = col.width / sumWidth;
            });

            const newColumnDefs = columnState.map(col => {
                const originalColDef = allColumns.find(c => c.field === col.colId);
                return {
                    ...originalColDef,
                    width: mapPercent[col.colId] * containerWidth
                };
            });

            api.setColumnDefs(newColumnDefs);
        }
    };

    const columnDefs = useMemo(() => [
        { headerName: "", field: "options", cellRenderer: (data) => <MenuOptions data={data} /> },
        { headerName: "ת.ז.", field: "student_tz", sortable: true, filter: true },
        { headerName: "שם", field: "firstName", sortable: true, filter: true, comparator: hebrewComparator },
        { headerName: "שנה", field: "year", sortable: true, filter: true },
        { headerName: "חודש", field: "month_name", sortable: true, filter: true },
        {
            headerName: "אושר",
            field: "approved",
            sortable: true,
            filter: true,
            cellRenderer: (params) => {
                 if (params.value) {
                    return <CheckIcon className='green-check'/>; // Approved icon in green
                } else if(params.value === 0) {
                    return <CloseIcon className='red-cross'/>;
                }else {
                     return '';
                 }
            }
        },
        { headerName: "סכום להחזר", field: "sum", sortable: true, filter: true },
        { headerName: "הערות", field: "comments", sortable: true, filter: true, comparator: hebrewComparator },
    ], []);

    const gridOptions = useMemo(() => ({
        enableRtl: true,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        }
    }), []);

    return (
        <div ref={containerRef} className="ag-theme-alpine table-container" style={{ height: 'calc(100vh - 170px)', zIndex: 0 }}>
            <AgGridReact
                enableCellTextSelection={true}
                ensureDomOrder={true}
                rowData={rowData}
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
            ></AgGridReact>
        </div>
    );
});

export default Table;