import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import {useTravelApprovedStore} from "../../../store/traveApprovedStore";

export const UpdateSumDialog = ({ open, onClose, id }) => {
    const [sum, setSum] = useState("");
    const { updateTravel } = useTravelApprovedStore();
    const handleSubmit = () => {
        updateTravel({id, sum}).then(r => console.log(r));
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>עדכן סכום להחזר</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="סכום"
                    type="number"
                    fullWidth
                    value={sum}
                    required={true}
                    onChange={(e) => setSum(e.target.value)}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Button onClick={onClose} color="primary">
                    ביטול
                </Button>
                <Button onClick={handleSubmit} color="primary">
                   עדכן
                </Button>
            </DialogActions>
        </Dialog>
    );
};