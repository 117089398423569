import { MenuItem } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {useTravelApprovedStore} from "../../../store/traveApprovedStore"; // Import the icon

export const DisapprovedCom = ({data}) => {
    const {data:{id}} = data
    const { updateTravel } = useTravelApprovedStore();
    return (
        <MenuItem onClick={() => {
            updateTravel({id, approved: false}).then(r => console.log(r));
            // disapprove(data); // Add your disapprove logic here
        }} className='menuButton'>
            <CloseIcon /> {/* Use the icon */}
            <p> לא מאושר </p> {/* Hebrew text for "Do not approve" */}
        </MenuItem>
    );
}