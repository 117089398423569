import React, { forwardRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { DeleteComponent } from './deleteComponent';
import {hebrewComparator} from "../utils/utils";

export const Table = forwardRef(({ data, setFetchData }, ref) => {
    const gridOptions = {
        enableRtl: true,
        defaultColDef: {
            sortable: true,
            filter: true,
        },
        sortModel: [{ colId: 'תאריך', sort: 'desc' }]
    };

    function comparator(valueA, valueB, nodeA, nodeB) {
        const dateA = new Date(nodeA.data.attendanceDate);
        const dateB = new Date(nodeB.data.attendanceDate);
        return dateA - dateB;
    }

    const columnDefs = [
        { headerName: "", field: "delete", cellRenderer: (data) => <DeleteComponent data={data} setFetchData={setFetchData} />, width: "60" },
        { headerName: "ת.ז.", field: "student_tz", sortable: true, filter: true, width: 120 },
        { headerName: "שם", field: "name", sortable: true, filter: true, width: 300, comparator: hebrewComparator },
        { headerName: "מסגרת", field: "framework", sortable: true, filter: true, width: 150 },
        { headerName: "שנה", field: "year", sortable: true, filter: true, width: 100 },
        { headerName: "חודש", field: "month", sortable: true, filter: true, width: 85 },
        { headerName: "שעות", field: "fullHours", sortable: true, filter: true, width: 100 },
        { headerName: "שעות לאקסל ", field: "excelHours", sortable: true, filter: true, width: 150 },
        { headerName: "רצופות", field: "consecutivePeriods", sortable: true, filter: true, width: 100 },
        {
            headerName: " תאריך",
            field: "hebDate",
            sortable: true,
            filter: true,
            width: 250,
            comparator: comparator,
            sort: 'desc'
        },
        { headerName: "מאוחר", field: "isLate", sortable: true, filter: true, width: 200 },
        {
            headerName: "הערות",
            field: "comments",
            sortable: true,
            filter: true,
            width: 300,
            comparator: hebrewComparator
        },
    ];

    return (
        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 170px)', width: '100%', zIndex: 0 }}>
            <AgGridReact
                enableCellTextSelection={true}
                ensureDomOrder={true}
                rowData={data}
                columnDefs={columnDefs}
                ref={ref}
                gridOptions={gridOptions}
            ></AgGridReact>
        </div>
    );
});