import { makeAutoObservable } from "mobx";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

class TravelApprovedStore {
    data = [];

    constructor() {
        makeAutoObservable(this);
    }

    setData(newData) {
        this.data = newData;
    }

    getFilteredData(filterCondition) {
        return this.data.filter(filterCondition);
    }


}

const travelApprovedStore = new TravelApprovedStore();

export const useTravelApprovedStore = () => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchData = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/get-days`, {
                method: 'GET'
            });
            const data = await response.json();
            travelApprovedStore.setData(data);
        } catch (error) {
            console.error("Failed to fetch data", error);
        }
    };

    const updateTravel = async (data) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/update-travel`, {
                method: 'PUT',
                body: JSON.stringify(data)
            });
            if (response.status === 200) {
                await fetchData();
            }else {
                console.error("Failed to approve travel", response);
            }
        } catch (error) {
            console.error("Failed to approve travel", error);
        }
    }

    const deleteTravel = async (id) => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/day-attandance/delete-travel/${id}`, {
                method: 'DELETE'
            });
            if (response.status === 200) {
                await fetchData();
            } else {
                console.error("Failed to delete travel", response);
            }
        } catch (error) {
            console.error("Failed to delete travel", error);
        }
    }

    return {
        updateTravel,
        travelApprovedStore,
        fetchData,
        deleteTravel
    };
};
