import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Delete} from "@mui/icons-material";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

export const DeleteComponent = ({data, setFetchData}) => {
    const fetchWithAuth = useFetchWithAuth();
    const ITEM_HEIGHT = 48;
    const deleteAttandance = async (attandance) => {
        let {data: newAttendance} = { ...attandance }
        newAttendance.framework = newAttendance.framework_id;
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/attandance/delete-attendance`, {
                method: "POST",
                body: JSON.stringify(newAttendance)
            });

            if (response.ok) {
                setFetchData(true);
            }
        } catch (ex) {
            console.error("error on fetching data");
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className='icon-button'
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            direction: 'rtl'
                        }
                    }
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    deleteAttandance(data)
                }} style={{direction: "rtl", height: "40px"}}>
                    <Delete></Delete>
                    <p style={{width: "100%", textAlign: "center"}}> מחיקה </p>
                </MenuItem>
            </Menu>
        </div>
    );
};