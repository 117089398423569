import {MenuItem} from "@mui/material";
import React from "react";
import {Delete} from "@mui/icons-material";
import {useTravelApprovedStore} from "../../../store/traveApprovedStore";

export const DeleteCom = ({data}) => {
    console.log(data.data)
    const {data:{id}} = data
    const { deleteTravel } = useTravelApprovedStore();
    return (
        <MenuItem onClick={() => {
            deleteTravel(id).then(r => console.log(r));

        }} className='menuButton'>
            <Delete />
            <p> מחיקה </p>
        </MenuItem>
    );
}