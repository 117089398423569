import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { UpdateSumDialog } from "./UpdateSumDialog";

export const UpdateSum = ({data} ) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const {data:{id}} = data

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <MenuItem onClick={handleClickOpen} className='menuButton'>
                <CloseIcon />
                <p> עדכן סכום להחזר </p>
            </MenuItem>
            <UpdateSumDialog open={dialogOpen} onClose={handleDialogClose} id={id} />
        </>
    );
};