import { MenuItem } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import {useTravelApprovedStore} from "../../../store/traveApprovedStore";
import {observe} from "mobx";
import {observer} from "mobx-react"; // Import the check icon

export const ApprovedCom = observer(({data}) => {
    const id = data.data.id
    const { updateTravel } = useTravelApprovedStore();
    return (
        <MenuItem onClick={() => {
            updateTravel({id, approved: true}).then(r => console.log(r));
        }} className='menuButton'>
            <CheckIcon />
            <p> מאושר </p>
        </MenuItem>
    );
})