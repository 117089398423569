import React, {useEffect, useMemo, useRef, useState} from "react";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import {ExcelButton} from "./excelButton";
import {Table} from "./Table";
import {Filters} from "./filters/filters";
import {georgianToStringHebrewDate} from "../utils/utils";

export const NewAttandanceTable = () => {
    const [attandance, setAttandance] = useState([]);
    const [filters, setFilters] = useState({});
    const [clearFilters, setClearFilters] = useState(false);
    const [fetchData, setFetchData] = useState(true);
    const fetchWithAuth = useFetchWithAuth();
    const gridData = useRef()
    const isFetching = useRef(false)


    const paymentFilter = (year, month, row) => {
        const {isLate, year: yearName, month: monthName} = row
        const fieldToFilter = (isLate || `${monthName}, ${yearName}`);
        const [monthVal, yearVal] = fieldToFilter.split(', ');
        if (year.length && !year.includes(yearVal)) return false;
        return !(month.length && !month.includes(monthVal));
    };

    const dateComparator = (valueA, valueB) => {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return dateB - dateA; // For descending order
    }

    useEffect(() => {
        const fetchData = async () => {

            try {
                if(!fetchData || isFetching.current) return;
                setFetchData(false);
                isFetching.current = true
                const response = await fetchWithAuth(`${process.env.REACT_APP_BASE_URL}/attandance`, {
                    method: "GET",
                });
                if (response.ok) {
                    const data = await response.json();
                    setAttandance(data);
                } else {
                    console.log('error fetching attandance');
                }
            } catch (error) {
                console.log(error);
            }
            finally {
                isFetching.current = false
            }
        }

        fetchData()
    }, [fetchData])

    const transformData = useMemo(() => {
        return attandance?.map((item) => {
            const hours = item.hours + (item.minutes / 60.0);
            const hours2 = hours > 0?  +hours.toFixed(2): null
            return ({
                ...item,
                student_tz: Number(item.student_tz),
                hebDate: georgianToStringHebrewDate(item.attendanceDate),
                name: `${item.description} ${item.lastName} ${item.firstName}`,
                framework: `כולל ${item.nameHe}`,
                fullHours: hours > 0? (item.hours < 10 ? '0' : '') + item.hours + ':' + (item.minutes < 10 ? '0' : '') + item.minutes: null,
                excelHours: hours2,
                consecutivePeriods: item.consecutivePeriods !== 0 ? item.consecutivePeriods : null
            })
        }).sort((a, b) => dateComparator(a.attendanceDate, b.attendanceDate));
    }, [attandance])

    const years = useMemo(() => {
        return [...new Set(transformData?.map(item => item.year))].sort()
    }, [transformData])

    const frameworks = useMemo(() => {
        return [...new Set(transformData?.map(item => item.framework))];
    }, [transformData])

    const filteredData = useMemo(() => {
        return transformData?.filter(data => {
            return Object.keys(filters).every(f => {
                switch (f){
                    case "isLate":
                        return (filters[f].filterValues["מאוחר"] && data[f]) || (filters[f].filterValues["רגיל"] && !data[f])
                    case "payment":
                        return  paymentFilter(filters[f].filterValues.year, filters[f].filterValues.month, data);
                    default:
                        return filters[f].filterValues[data[f]];
                }
            });
        });
    }, [transformData, filters])


    return (
        <div>
            <div style={{marginBottom: "10px", display: "flex", justifyContent: "space-between", zIndex: 1}}>
                <div style={{display: "flex", zIndex: 2}}>
                    <Filters years={years} frameworks={frameworks} clearFilters={clearFilters} setClearFilters={setClearFilters} filters={filters} setFilters={setFilters}/>
                </div>
                <ExcelButton data={gridData}></ExcelButton>
            </div>
            <Table data={filteredData} ref={gridData} setFetchData={setFetchData}></Table>
        </div>
    );
}