import exceljs from "exceljs";
import { Button } from "@mui/material";
import { saveAs } from 'file-saver';
import React from "react";

export const ExcelButton = ({ data }) => {
    const convertToExcel = () => {
        const currentData = data.current.api.getModel().rowsToDisplay.map(rowNode => {
            const rowData = { ...rowNode.data };
            Object.keys(rowData).forEach(key => {
                if (!rowData[key]) {
                    rowData[key] = null;
                }
            });
            return rowData;
        });

        const workbook = new exceljs.Workbook();
        const regularWorksheet = workbook.addWorksheet('דוחות רגילים');
        const lateWorksheet = workbook.addWorksheet('דוחות מאוחרים');

        const columns = [
            { header: 'ת.ז', key: 'student_tz', width: 10 },
            { header: 'שם', key: 'name', width: 20 },
            { header: 'מסגרת', key: 'framework', width: 10 },
            { header: 'שנה', key: 'year', width: 10 },
            { header: 'חודש', key: 'month', width: 20 },
            { header: 'שעות', key: 'excelHours', width: 10, type: 'number' },
            { header: 'רצופות', key: 'consecutivePeriods', width: 20, type: 'number' },
            { header: 'תאריך דיווח', key: 'hebDate', width: 10 },
            { header: 'מאוחר', key: 'isLate', width: 10 },
            { header: 'הערות', key: 'comments', width: 30 }
        ];

        regularWorksheet.columns = columns;
        lateWorksheet.columns = columns;

        currentData.filter(d => d.isLate).forEach(item => {
            lateWorksheet.addRow(item);
        });

        currentData.filter(d => !d.isLate).forEach(item => {
            regularWorksheet.addRow(item);
        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'דוחות.xlsx');
        });
    };

    return (
        <Button style={{ maxHeight: "30px", direction: "rtl", margin: "15px" }} variant="contained" onClick={convertToExcel}> ייצא ל excel</Button>
    );
};