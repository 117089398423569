import React, {memo, useEffect, useMemo} from 'react';
import { TextField, MenuItem } from '@mui/material';
import { getJewishYears } from 'jewish-dates-core';
import { convertToHebrewLetters, getHebrewMonth } from './utils/utils';

export const YearAndMonth = memo(function YearAndMonth({ year, month, setYear, setMonth, disable }) {
    const years = useMemo(() => getJewishYears(), []);
    const months = useMemo(()=>getHebrewMonth(), [] )
    useEffect(() => {
        console.log(months)
    }, [months]);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', height: '50%' }}>
            <TextField
                select
                label="שנה"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled={disable}
                variant="filled"
                style={{ width: '45%', paddingBottom: '10px' }}
            >
                {years.map((year) => (
                    <MenuItem key={year} value={year}>
                        {convertToHebrewLetters(year)}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                select
                label="חודש"
                variant="filled"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                disabled={disable}
                style={{ width: '45%' }}
            >
                {months.map(( name ) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
});